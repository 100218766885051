import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import HeaderSection from "../../components/HeaderSection";
import Logo from "../../../public/assets/A-logo-primary.svg";


const Unsubscribe = ({ location }) => {
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const theme = useTheme();
  const email = urlParams.get("email");
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const unsubscribe = async () => {
      if (email) {
        try {
          const res = await fetch(`${process.env.GATSBY_STRAPI_URL}/api/unsubscribe?email=${email}`,
            {
              headers: { "Authorization": `Bearer ${process.env.GATSBY_STRAPI_TOKEN}` }
            })
          await res.json()
        } catch (error) {
          console.log(error)
        } finally {
          setLoading(false)
        }
      }
    }
    unsubscribe()
  }, [email])

  return (
    <Layout location={location}>

      <Container
        maxWidth="lg"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: theme.spacing(8),
          marginBottom: theme.spacing(0),
        }}
      >

        {
          loading ?
            <CircularProgress />
            :
            <>
              <Logo fill={theme.palette.primary.main} height={200} />
              <HeaderSection
                header="Unsubscribe successful"
                subheader="Your email has been successfully removed from our mailing list!"
                location={location}
              />
            </>}
      </Container>
    </Layout>
  );
};

export default Unsubscribe;